<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <span v-if="props.column.field === 'createdAt'">
              {{ $dateFormatter(props.row.createdAt, $i18n.locale)  }}
            </span>
            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-else-if="props.column.field === 'action'"
            >
              <!-- <div
                class="cursor-pointer"
                @click="$router.push(`/news/${props.row.id}/show`)"
              >
                <feather-icon size="16" icon="EyeIcon" />
              </div> -->
              <div
                v-if="$can(`${$route.meta.permission}.edit`)"
                class="cursor-pointer"
                @click="$router.push(`/news/${props.row.id}/edit`)"
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.delete`)"
                class="cursor-pointer"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <b-row>
        <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5>{{ row.subject }}</h5>
              <span class="d-flex gap-3">
                <div
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  class="cursor-pointer"
                  @click="$router.push(`/news/${row.id}/edit`)"
                >
                  <feather-icon size="18" icon="Edit2Icon" />
                </div>
                <div
                  v-if="$can(`${$route.meta.permission}.delete`)"
                  class="cursor-pointer"
                  @click="destroy(row.id)"
                >
                  <feather-icon size="18" icon="TrashIcon" />
                </div>
              </span>
            </div>
            <div class="card-body pt-0">
              <p>
                <strong>{{ $t("Language") }}: </strong>{{ row.language }}
              </p>
              <p>
                <strong>{{ $t("Creation Date") }}: </strong
                >{{ $dateFormatter(row.createdAt, $i18n.locale) }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-center mt-2">
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--==================================-->
    <!--==================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
  },

  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create News"),
          icon: "PlusIcon",
          path: "/news/create",
          permission: `${this.$route.meta.permission}.create`,
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("News"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Subject"),
          field: "subject",
        },
        {
          label: this.$t("Language"),
          field: "language",
        },
        {
          label: this.$t("Creation Date"),
          field: "createdAt",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("news/list", {
          page: this.page,
          ...this.form,
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("news/destroy", id).finally(() => {
          this.loadItems();
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
